import * as React from 'react';
import Pages from '../components/Pages';
import { navigate } from 'gatsby';
import Planets from 'images/planets.svg';
import CustomContainer from '../components/CustomContainer';

const MenuContainer = ({ className, linkTo, title }) => (
  <div
    className={`${className} cursor-pointer hover:opacity-50 duration-300`}
    onClick={() => {
      navigate(`${linkTo}`);
    }}
  >
    {title}
  </div>
);

const NotFoundPage = () => {
  return (
    <Pages>
      <div className="bg-brand-black text-brand-white h-screen">
        <CustomContainer className="h-full flex flex-col justify-center">
          <p className="text-xl lg:text-6xl font-semibold">404 Error</p>
          <p className="text-[24px] lg:text-lg font-semibold leading-tight">
            This page is currently unavailable.
          </p>
          <p className="text-sm sm:text-base font-thin mt-3">
            Check out our other pages for the information you're looking for.
          </p>
          <div className="mt-8 sm:mt-12 relative">
            <img
              src={Planets}
              alt="planets"
              className="absolute bottom-0 right-0 w-[170px] lg:w-[300px]"
            />
            <MenuContainer
              className="text-[32px] lg:text-xl font-semibold"
              linkTo="/our-approach"
              title="Our Approach"
            />
            <MenuContainer
              className="text-[32px] lg:text-xl font-semibold"
              linkTo="/portfolio"
              title="Portfolio"
            />{' '}
            <MenuContainer
              className="text-[32px] lg:text-xl font-semibold"
              linkTo="/team"
              title="Team"
            />
            <MenuContainer
              className="text-sm sm:text-base font-thin my-4"
              linkTo="/news"
              title="News"
            />
            <MenuContainer
              className="text-sm sm:text-base font-thin"
              linkTo="/contact-us"
              title="Contact Us"
            />
          </div>
        </CustomContainer>
      </div>
    </Pages>
  );
};

export default NotFoundPage;
